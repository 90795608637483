<template>
  <v-app>
    <v-app-bar
      color="primary"
      height="80"
      app
    >
      <img src="./assets/logo_MMA_2.svg" height="60" />
      <v-app-bar-title>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <div class="float-right d-lg-flex d-md-flex d-xl-flex d-sm-flex d-none">
        <v-btn color="accent" href="https://www.prodejzanejvic.cz/#contact_form" target="_blank">chci prodat v aukci</v-btn>
      </div>
    </v-app-bar>

    <v-main class="main">
      <v-container>
      <template v-if="stream">
        <v-row class="mt-1">
          <v-col class="col-12">
          <h2> {{ title }}</h2>
          </v-col>
        </v-row>
      <v-responsive id="video-container" class="d-flex align-stretch justify-center ma-auto" max-width="1200" :aspect-ratio="16/9">
        <div class="elevation-4 main-video">
        <you-tube v-if="showVideo" :video-id="videoId" max-width="1200" ></you-tube>
          <div v-else class="videoPlaceholder fill-height justify-center align-center d-flex flex-nowrap">
            <v-sheet class="countdown rounded-lg justify-center ma-auto elevation-7" :width="$vuetify.breakpoint.xs ? '90%' : '30%'">
              <div v-if="!loading">
              <div class="mt-4">nejbližší aukce začne za</div>
              <div class="timer"> {{remainToString(remaining) }}</div>
              <div class="flex-nowrap">dny : hodiny : minuty : sekundy</div>
              </div>
              <v-skeleton-loader type="heading" v-else></v-skeleton-loader>
             </v-sheet>
          </div>
        </div>
      </v-responsive>
<v-row>
</v-row>
      </template>
      <div v-else-if="!loading" class="justify-center">
        <v-alert type="warning">Žádná aukce není naplánována</v-alert>
      </div>
        <div class="d-sm-none text-center mt-5">
          <v-btn color="accent" href="https://www.prodejzanejvic.cz/#contact_form" target="_blank">chci prodat v aukci</v-btn>
        </div>

      </v-container>
      <v-footer color="transparent" padless>
        <v-img  width="100%" src="./assets/footer.svg"></v-img>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'
import YouTube from "./components/YouTube";

let timerInterval = null
export default {
  name: 'App',
  components: {YouTube},
  head: {
  },
  data: () => ({
    //
    videoId: 'IwzjgvedxWk',
    title : '',
    stream: null,
    loading: true,
    remaining: 999999,
    begin: null
  }),
  computed: {
    showVideo() {
      return this.remaining < 120000
    }
  },
  mounted() {
    axios.get(process.env.VUE_APP_BACKEND_URL+ '/streams-oncoming').then( r => {
      const data  = r.data
      this.stream = data
      this.videoId = data.youtubeId
      this.title = data.description
      this.begin = new Date(data.begin)
      timerInterval = window.setInterval(() => {
        const currentTime = new Date()
        this.remaining =  this.begin.getTime() - currentTime.getTime()
        this.loading = false
        if (this.remaining < 0)
        {
          this.remaining = 0
        }
      }, 1000)
    }).catch( () => {
      this.loading = false
    })
  },
  destroyed() {
    clearInterval(timerInterval)
  },
  methods: {
    remainToString(epoch) {
      let minutes = parseInt(epoch / 60000, 10);
      let seconds = parseInt(epoch % 60000 / 1000, 10);
      let hours = Math.floor(minutes / 60)
      minutes = minutes % 60
      let days = Math.floor(hours / 24)
      hours = hours  % 24

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      hours = hours < 10 ? "0" + hours : hours;

      return `${days}:${hours}:${minutes}:${seconds}`
    },
  }
};
</script>
<style scoped lang="css">
.main-video {
  height: 100%;
  background-color: #F9FFF2;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.main {
  background-color: #F9FFF2;
}

h2 {
  color:#1b5e20;
}

.countdown {
}

.videoPlaceholder {
  width: 100%;
  background-image: url("./assets/mmaukce.tv.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  paddingBottom: "calc(56.25%)",

}

.videoPlaceholder img
{
  position: absolute;
  top: 0;
}

.videoPlaceholder .countdown
{
  padding: 1rem;
  text-align: center;
  background: #000000;
  color: #ffffff;
}

.timer
{
  font-size: xx-large;
  font-weight: 800;
}

</style>
