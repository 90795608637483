import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import cs from 'vuetify/lib/locale/cs';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#3CA220',
        secondary: '#424242',
        accent: '#ff0000',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
    lang: {
      locales: { cs },
      current: 'cs',
    },
});
